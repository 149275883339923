<template>
  <div>
    <v-card-text class="">
      <v-card-title class="text-xl">Selectionner votre adresse ou remplissez une nouvelle</v-card-title>
      <v-card-text>
        <v-form
            ref="form"
            @submit.prevent="validate"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-expansion-panels>

                <v-expansion-panel
                    key="address"
                >
                  <v-expansion-panel-header>
                    Selectionner une adresse
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list-item-group
                        active-class=""
                    >
                      <v-radio-group v-model="customer">
                        <v-list-item v-for="item in address" :key="item.id">
                          <template v-slot:default="{  }">
                            <v-list-item-action>
                              <v-radio
                                  :key="item.id"
                                  :value="item">
                              </v-radio>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>{{ item.firstname }} - {{item.lastname}}</v-list-item-title>
                              <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </v-radio-group>
                    </v-list-item-group>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="customer.firstname"
                  hide-details
                  class="mb-3"
                  outlined
                  label="Nom Destinataire"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="customer.lastname"
                  hide-details
                  class="mb-3"
                  outlined
                  label="Prenom Destinataire"
                  required
              ></v-text-field>

              <v-text-field
                  hide-details
                  class="mb-3"
                  outlined
                  v-model="customer.phonenumber"
                  label="Numéro de téléphone"
                  required
              ></v-text-field>

            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  hide-details
                  class="mb-3"
                  outlined
                  v-model="customer.email"
                  :rules="emailRules"
                  label="E-mail"
                  required
              ></v-text-field>

              <v-text-field
                  hide-details
                  class="mb-3"
                  outlined
                  v-model="customer.postalcode"
                  label="Code Postal"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-dialog
              :persistent="dialog"
              elevation="0"
              v-model="dialog">
            <v-card-text>
              <div class="container mt-5 mb-3">
                <div class="row d-flex justify-content-center">
                  <div class="col-md-8">
                    <div class="card">

                      <v-toolbar elevation="0" >
                        <v-btn
                            icon
                            dark
                            class="text--black"
                            @click="dialog = false"
                        >
                          <v-icon color="primary">mdi-close</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <div class="d-flex flex-row p-2">
                        <table class="table table-borderless">
                          <tbody>
                          <tr class="content" v-if="customer_location.tarrif_ref">
                            <div class="d-flex justify-content-between">
                              <div class="amount">
                                <h4>Transporter: {{ customer_location.tarrif_ref.transporter.name }}</h4>
                                Téléphone: <span class="text-primary font-weight-bold">{{ customer_location.tarrif_ref.transporter.telephone }}</span> <br/>
                                Email: <small>{{ customer_location.tarrif_ref.transporter.email }}</small>,<br>
                                Tarif: <b><small>{{ customer_location.tarrif_ref.cost }}</small> XAF </b>,<br>
                                Poids du Colis: <b><small>{{ customer_location.weight }}</small> kg </b>
                              </div>
                              <div class="d-flex flex-row align-items-center">
                                <img :src="customer_location.tarrif_ref.transporter.urlImage" width="100" class="rounded-circle">
                              </div>
                            </div>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr>
                      <div class="table-responsive p-2">
                        <v-alert

                            v-model="alert.visible"
                            border="bottom"
                            dense
                            prominent
                            dismissible
                            :type="alert.type"
                            class="w-100"
                        >
                          {{alert.text}}
                        </v-alert>
                        <table class="table table-borderless">
                          <tbody>
                          <tr class="add">
                            <td>Expediteur</td>
                            <td>Receveur</td>
                          </tr>
                          <tr class="content">
                            <td class="font-weight-bold">{{ sender.firstname }} {{ sender.lastname }} <br> Pays: {{ customer_location.country }} <br> Tél: {{ sender.phonenumber }} <br> Ville:{{ customer_location.departure }} <br> BP: {{ sender.postalcode }}</td>
                            <td class="font-weight-bold">{{ customer.firstname }} {{ customer.lastname }} <br> Pays: {{ customer_location.destination_country }} <br> Tél: {{ customer.phonenumber }} <br> Ville:{{ customer_location.arrival }} <br> BP: {{ customer.postalcode }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr>
                      <div class="products p-2">
                        <table class="table table-borderless">
                          <tbody>
                          <tr v-if="check_paiement">

                            <span>Procéder au paiement </span>
                            <v-col
                                cols="12"
                                md="12"
                            >

                              <v-text-field
                                  hide-details
                                  class="mb-3"
                                  small
                                  outlined
                                  v-model="customer.send_phonenumber"
                                  label="Entrer le Numéro de paiement"
                                  required
                              ></v-text-field>
                              <v-radio-group v-model="paymentmethod">
                                <v-row>
                                  <v-col v-for="item in paymentmethods" :key="item.id" cols="4">
                                    <v-list-item>
                                      <template v-slot:default="{  }">
                                        <v-list-item-action v-if="item.id != 4">
                                          <v-radio
                                              :key="item.id"
                                              :value="item.id">
                                          </v-radio>
                                        </v-list-item-action>

                                        <v-list-item-content v-if="item.id != 4">
                                          <v-list-item-title>{{ item.name }} </v-list-item-title>
                                          <v-list-item-subtitle>
                                            <v-img
                                                :lazy-src="item.image"
                                                width="50px"
                                                :src="item.image"
                                            ></v-img>
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </v-col>
                                </v-row>
                              </v-radio-group>
                            </v-col>
                            <v-col
                                v-if="paymentmethod"
                                cols="12"
                                md="12"
                            >
                              <v-btn
                                  block
                                  color="success"
                                  class="mt-6"
                                  :loading="loading"
                                  @click="init_payment"
                              >
                                initier le paiement
                              </v-btn>
                            </v-col>
                          </tr>
                          <tr v-else>
                            <v-col
                                cols="12"
                                md="12"
                            >

                              <v-alert
                                  border="bottom"
                                  color="success"
                                  dark
                              >
                                {{ check_paiement_message }}
                              </v-alert>
                            </v-col>
                            <v-col
                                cols="12"
                                md="12"
                            >
                              <v-card-actions>
                                <v-btn
                                    color="error"
                                    text
                                    @click="close"
                                >
                                  Fermer
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :loading="loading"
                                    color="primary"
                                    dark
                                    @click="checkpayment"
                                >
                                  <v-icon
                                      left
                                      dark
                                  >
                                    mdi-content-save-check
                                  </v-icon>
                                  Terminer le paiement
                                </v-btn>
                              </v-card-actions>
                            </v-col>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-dialog>
          <v-btn
              block
              color="success"
              class="mt-6 w-50"
              :loading="loading"
              type="submit"
              small
          >
            Sauvegarder
          </v-btn>

        </v-form>

      </v-card-text>
    </v-card-text>
  </div>
</template>

<script>

import {Drequest} from "@/plugins/Drequest";

export default {
  name: "Destination",
  props: ['nextStep'],
  data: () => ({
    alert: {
      type:'error',
      text: 'initialisation du texte',
      visible: false
    },
    dialog: false,
    sender: {},
    check_paiement: true,
    paymentmethod:'',
    check_paiement_message:'',
    costumer_location: {},
    transaction: {},
    loading: false,
    address: [],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    customer: {},
    paymentmethods: []
  }),
  beforeMount() {
    this.loadData();
  },
  methods: {
    loadData: function () {
      this.sender = JSON.parse(this.$store.state.user);
      Drequest.api("lazyloading.address?dfilters=on&user.id:eq="+ this.sender.id)
          .get((response) => {
            this.address = response.listEntity;
          });
      this.customer_location = this.$store.state.customer_location;
    },
    checkpayment: function(){
      this.loading = true;
      Drequest.init("https://aggregator.spacekola.com/api/payment.verify")
          .param({
            "id": this.transaction.id,
          })
          .get((response) => {
            if(response.data.success){
              this.save_order();
            }else{
              this.alert = {
                type: 'error',
                text: 'echec du paiement, valider l\'opération via votre téléphone',
                visible: true
              };
              this.loading = false;
            }
          });
    },
    init_payment: function(){
      this.loading = true;
      Drequest.init("https://aggregator.spacekola.com/api/payment-domain.confirm?token=fSF7e0FE.41ddb416b5842ccab91ca89d73d706fdb67aecea")
          .data({
            "payment": {
              "amount": this.customer_location.tarrif_ref.cost,
              "reference": "test api",
              "currency_iso":"xaf",
              "payment_method.id": this.paymentmethod
            },
            "payment_parameters": {"phonenumber": this.customer.send_phonenumber}
          })
          .raw((response) => {
            if(response.success){
              this.check_paiement = !response.success;
              this.check_paiement_message = response.message;
              this.transaction = response.payment;
            }else{
              this.alert = {
                type: 'error',
                text: response.detail,
                visible: true
              };
            }
            this.dialog = true;
            this.loading = false;
          });
    },
    save_order: function(){
      this.loading = true;
        let dt ={
          "receiver": {
            "firstname":this.customer.firstname,
            "lastname":this.customer.lastname,
            "email":this.customer.email,
            "telephone":this.customer.phonenumber,
            "country_iso":this.customer_location.destination_country,
            "reference":"",
            "address":""
          },
          "sender": {
            "firstname":this.sender.firstname ? this.sender.firstname : 'user',
            "lastname":this.sender.lastname,
            "email":this.sender.email,
            "telephone":this.sender.phonenumber,
            "country_iso":this.customer_location.country,
            "reference":"",
            "address":""
          },
          "order": {
            "user.id": this.sender.id,
            "paymentreference": this.transaction.paymentref,
            "tarrif_ref": this.customer_location.tarrif_ref.id,
            "weight": this.customer_location.weight,
            "shippingcost": this.customer_location.tarrif_ref.cost,
            "num_facture": "",
            "customername": "",
            "address": "",
            "deliverymode": this.customer_location.deliveryoption
          }
        };
        Drequest.api("transporter.order")
            .data(dt)
            .raw((response) => {
              console.log(response);
              this.loading = false;
              this.close();
              this.nextStep(2);
            });

    },
    close: function (){
      this.dialog = false;
    },
    validate: function(){
      this.loading = true;
      this.$store.commit('save_customer_address', this.customer);
      Drequest.init("https://aggregator.spacekola.com/api/payment.available")
          .param({
            "country": this.customer_location.country,
          })
          .get((response) => {
            this.paymentmethods = response.paymentmethods;
            this.dialog = true;
            this.loading = false;
          });

    }
  }
}
</script>

<style lang="scss">
@import '@/plugins/vuetify/default-preset/preset/pages/auth.scss';
.auth-wrapper.auth-v1 .auth-inner {
  width: 50rem !important;
  z-index: 1;
}
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');

body {
  font-family: 'Maven Pro', sans-serif;
  background-color: #f12369
}

hr {
  color: rgba(101, 96, 96, 0.31);
  margin-top: 5px;
  margin-bottom: 5px
}

.add {
  color: #827f7f;
  text-transform: uppercase;
  font-size: 12px
}
.colorCredit {
  color: #0d6efc;
}
.content {
  font-size: 14px
}

.v-dialog {
  box-shadow: none !important;
}
</style>
