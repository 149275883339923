<template>
  <div>
    <main>
      <div class="container py-4">
        <header class="header navbar-area">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-12">
                <div class="nav-inner">
                  <!-- Start Navbar -->
                  <nav class="navbar navbar-expand-lg">
                    <a class="navbar-brand" href="/application">
                      <img :src="require('@/assets/images/logos/logo.png')" style="width: 100px; height: auto;"/>
                    </a>

                    <a
                        href="/application"
                        style="color: white"
                        class="navbar-toggler mobile-menu-btn border-1 border-white p-2"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on bottom"
                        aria-expanded="false" aria-label="Toggle navigation">
                      <v-icon style="color: white" size="30">{{ icons.mdiReply }} </v-icon>
                    </a>
                    <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">

                    </div> <!-- navbar collapse -->
                    <div class="button add-list-button">
                      <a href="javascript:window.history.go(-1)" class="btn btn-primary text-white"><v-icon style="color: white" size="20">{{ icons.mdiReply }} </v-icon> Retourner</a>
                    </div>
                  </nav>
                  <!-- End Navbar -->
                </div>
              </div>
            </div> <!-- row -->
          </div> <!-- container -->
        </header>
        <!-- End Header Area -->

        <v-stepper v-model="e1">
          <div class="p-5 mb-4 bg-light rounded-3">
            <div class="container-fluid py-5">

              <v-stepper-header>
                <template v-for="n in steps">
                  <v-stepper-step
                      :key="`${n}-step`"
                      :complete="e1 > n"
                      :step="n"
                      editable
                  >
                    <span v-if="n == 1">Localisation de mon colis <v-divider></v-divider></span>
                    <span v-else-if="n == 2">Adresse Destination <v-divider></v-divider></span>
                    <span v-else>Comfirmation <v-divider></v-divider></span>
                  </v-stepper-step>

                  <v-divider
                      v-if="n !== steps"
                      :key="n"
                  ></v-divider>
                </template>
              </v-stepper-header>
            </div>

            <v-stepper-items>
              <v-stepper-content
                  v-for="n in steps"
                  :key="`${n}-content`"
                  :step="n"
              >
                <location-colis :nextStep="nextStep" v-if="n == 1"/>
                <destination :nextStep="nextStep" v-if="n == 2"/>
                <v-card v-if="n == 3" dark color="primary">
                  <v-card-text>votre colis a été enregistrer avec success <a href="/dashboard"
                                                           class="text-white"
                  > Retourner sur le tableau de bord</a>
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </div>
        </v-stepper>
        <footer class="pt-3 mt-4 text-muted border-top">
          © 2021
        </footer>
      </div>
    </main>
  </div>
</template>

<script>
import LocationColis from "./sendcolis/LocationColis";
import Destination from "./sendcolis/Destination";
import {mdiReply} from '@mdi/js'

export default {
  data () {
    return {
      icons: { mdiReply },
      e1: 1,
      steps: 3,
    }
  },
  components:{
    LocationColis,
    Destination
  },
  watch: {
    steps (val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    },
  },

  methods: {
    prevStep(n) {
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n - 1
      }
    },
    nextStep (n) {
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
      }
    },
  },
}
</script>


<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>