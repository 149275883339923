<template>
  <div>
    <v-card-text class="">
      <v-card-text>
        <v-alert
            v-show="alert.active"
            dismissible
            :color="alert.color"
            border="left"
            elevation="2"
            colored-border
            :icon="alert.icons"
        >
          {{ alert.text }}
        </v-alert>
        <v-form
            ref="form"
            @submit.prevent="validate"
        >
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <v-card-title class="text-sm">Adresse de départ</v-card-title>
              <v-col lg="12">
                <v-select
                    :items="countries"
                    item-value="iso"
                    @change="getDepartureCities"
                    item-text="name"
                    outlined
                    dense
                    label="Pays de départ" v-model="departureItem['countryiso']">
                </v-select>
              </v-col>
              <v-col lg="12" v-if="departureItem['countryiso']">

                <v-autocomplete
                    v-model="departureItem.cityname"
                    :items="citiesDeparture"
                    :search-input.sync="searchcityDeparture"
                    :loading="isDestinationLoading"
                    outlined
                    dense
                    item-value="name"
                    item-text="name"
                    chips
                    small-chips
                    label="Ville de départ"
                ></v-autocomplete>

              </v-col>

            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-card-title class="text-sm">Adresse de livraison de colis</v-card-title>
              <v-col lg="12">
                <v-select
                    :items="countries"
                    item-value="iso"
                    @change="getArrivalCities"
                    item-text="name"
                    outlined
                    dense
                    label="Destination" v-model="arrivalItem['countryiso']">
                </v-select>
              </v-col>
              <v-col lg="12" v-if="arrivalItem['countryiso']">
                <v-autocomplete
                    v-model="arrivalItem.cityname"
                    :items="citiesArrival"
                    :search-input.sync="searchcityArrival"
                    :loading="isDestinationLoadingArrival"
                    outlined
                    dense
                    item-value="name"
                    item-text="name"
                    chips
                    small-chips
                    label="Ville d'arrivée"
                ></v-autocomplete>
                <v-text-field
                    hide-details
                    class="mb-3"
                    small
                    outlined
                    v-model="editedItem.weight"
                    label="Poids en kg"
                    required
                ></v-text-field>
              </v-col>
            </v-col>
          </v-row>

          <div class="d-flex align-center justify-space-between flex-wrap">
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <p class="text--primary mt-n3 mb-2">
                  Option de livraison
                </p>
                <v-radio-group
                    @change="loadTarif"
                    v-model="editedItem['tree_item\\deliverymode']"
                    row
                    class="mt-0"
                >
                  <v-radio
                      v-for="tt in deliverymodes"
                      :key="tt.name"
                      :label="tt.name"
                      :value="tt.name"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-btn
                    v-if="loadtransporter"
                    :loading="loadtransporter"
                    :disabled="loadtransporter"
                    color="blue-grey"
                    class="ma-2 white--text"
                    fab
                >
                </v-btn>
                <v-expansion-panels v-else>
                  <v-expansion-panel
                      v-model="panel"
                      key="location"
                  >
                    <v-expansion-panel-header>
                      Selectionner le transporter
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list-item-group
                          active-class=""
                      >
                        <v-radio-group v-model="editedItem['tarif']">
                          <v-list-item v-for="item in tarifs" :key="item.id">
                            <template v-slot:default="{  }">
                              <v-list-item-action>
                                <v-radio
                                    :key="item.id"
                                    :value="item">
                                </v-radio>
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title>{{ item.cost }}XAF</v-list-item-title>
                                <v-list-item-subtitle>{{ item.transporter.name }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-radio-group>
                      </v-list-item-group>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </div>

          <v-btn
              block
              color="success"
              class="mt-6 w-50"
              small
              :loading="loading"
              type="submit"
          >
            suivant
          </v-btn>

        </v-form>

      </v-card-text>
    </v-card-text>
  </div>
</template>

<script>
import {mdiAlertRemove, mdiThumbUp} from '@mdi/js'
import {Drequest} from "@/plugins/Drequest";
export default {
  name: "LocationColis",
  props: ['nextStep'],
  data: () => ({
    panel: false,
    tab: {},
    supplements: [],
    deliverymodes: [],
    loading: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    social_reasons: [],
    checkbox: true,
    valid: true,
    dialog: false,
    loadtransporter: false,
    response: {},
    transporter: {},
    countries: [],
    citiesDeparture: [],
    tarifs: [],
    citiesArrival: [],
    searchcityArrival: "",
    searchcityDeparture: "",
    isDestinationLoadingArrival: false,
    isDestinationLoading: false,
    icons: {
      mdiAlertRemove,
      mdiThumbUp
    },
    inprocess: false,
    editedItem: {},
    departureItem: {},
    arrivalItem: {},
    alert: {
      color: '',
      text: '',
      icons: mdiThumbUp,
      active: false
    },
  }),
  beforeMount() {
    this.initialize();
    this.loadDelivery();
  },
  methods: {
    getDepartureCities() {
      this.isDestinationLoading = true
      Drequest.api("city.fetch?country_code=" + this.departureItem['countryiso'])
          .get((response) => {
                console.log(response)
                this.citiesDeparture = response.cities;
                this.isDestinationLoading = false
              }
          )
    },
    getArrivalCities () {
      this.isDestinationLoadingArrival = true
      Drequest.api("city.fetch?country_code=" + this.arrivalItem['countryiso'])
          .get((response) => {
                console.log(response)
                this.citiesArrival = response.cities;
                this.isDestinationLoadingArrival = false
              }
          )
    },
    getCitiesByNameDeparture(name) {
      if (!name)
        return 1;

      if (name.length < 2 || this.isDestinationLoading)
        return 1;

      this.isDestinationLoading = true
      Drequest.api("city.fetch?name=" + name + "&country_code=" + this.departureItem['countryiso'])
          .get((response) => {
                console.log(response)
                this.citiesDeparture = [...this.cities, ...response.cities];
                this.isDestinationLoading = false
              }
          )

    },
    getCitiesByNameArrival(name) {
      if (!name)
        return 1;

      if (name.length < 2 || this.isDestinationLoadingArrival)
        return 1;

      this.isDestinationLoadingArrival = true
      Drequest.api("city.fetch?name=" + name + "&country_code=" + this.arrivalItem['countryiso'])
          .get((response) => {
                console.log(response)
                this.citiesArrival = [...this.cities, ...response.cities];
                this.isDestinationLoadingArrival = false
              }
          )

    },
    loadDelivery: function () {
          Drequest.api('lazyloading.tree-item?dfilters=on&tree.name:eq=deliverymode')
              .get((response) => {
                this.deliverymodes = response.listEntity;
              });
    },
    initialize(){
      Drequest.api("lazyloading.country?dfilters=on&per_page=all&next=1")
          .get((response) => {
            this.countries = response.listEntity;
          });
    },
    loadTarif(){
      this.loadtransporter = true;
      let dt = new FormData();
      dt.append('country', this.departureItem.countryiso);
      dt.append('shop_country', this.departureItem.countryiso);
      dt.append('weight', this.editedItem.weight);
      dt.append('destination', this.arrivalItem.cityname);
      dt.append('departure',  this.departureItem.cityname);
      dt.append('deliveryoption', this.editedItem["tree_item\\deliverymode"]);
      Drequest
          .api("tarrif.available")
          .data(dt)
          .post((response) => {
            if(response.success){
              this.tarifs = response.tarrif1s;
              this.loadtransporter = false;
              this.panel = true;
            }
          });
    },

    validate(){
      let data = {
        country: this.departureItem.countryiso,
        shop_country: this.departureItem.countryiso,
        destination_country: this.arrivalItem.countryiso,
        weight: this.editedItem.weight,
        destination: this.arrivalItem.cityname,
        departure: this.departureItem.cityname,
        deliveryoption: this.editedItem["tree_item\\deliverymode"],
        tarrif_ref: this.editedItem["tarif"]
      }
      this.$store.commit('save_customer_location', data);
      this.nextStep(1);
    }
  },
  watch: {
    searchcityDeparture(val) {
      this.getCitiesByNameDeparture(val)
    },
    searchcityArrival(val) {
      console.log(val)
      this.getCitiesByNameArrival(val)
    },
  },
}
</script>

<style lang="scss">
@import '@/plugins/vuetify/default-preset/preset/pages/auth.scss';
.auth-wrapper.auth-v1 .auth-inner {
  width: 50rem !important;
  z-index: 1;
}
@media (max-width: 360px) {
  .v-stepper__content {
    top: 0;
    padding: 0px !important;
    flex: 1 0 auto;
    width: 100%;
  }
  .v-card__subtitle, .v-card__text, .v-card__title {
    padding: 0px !important;
  }
}

</style>